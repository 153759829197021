export default [
    {
        path: "/",
        name: "index",
        component: () => import( "v/index/home/index"),
        meta: {
            title: "首页",
            authorization:true
        },
    },
    {
        path: "/envir",
        name: "envirPhoto",
        component: () => import( "v/index/home/envirPhoto"),
        meta: {
            title: "照片上传",
            authorization:true
        },
    },
];
