export default {
  // 更新上一页信息
  setPrePage(state, path) {
    state.previousPage = path;
  },
  setCameras(state, camera) {
    state.isLogin = camera;
    localStorage.setItem("monitor-camera", camera);
  },
  login(state,token) {
    if(token){
      state.isLogin = "1";
      localStorage.setItem("monitor-isLogin", "1");
      localStorage.setItem("monitor-token", token);
    }
  },
  loginOut(state) {
    state.isLogin = "0";
    for (let k in window.localStorage) {
      if (k.indexOf('monitor-signature')!=-1) {
        window.localStorage.removeItem(k);
      }
      if (k.indexOf('monitor-hintDialog')!=-1) {
        window.localStorage.removeItem(k);
      }
    }
    localStorage.removeItem("monitor-examName");
    localStorage.removeItem("monitor-isLogin");
    localStorage.removeItem("monitor-has_step");
    localStorage.removeItem("monitor-zkz");
    localStorage.removeItem("monitor-tokenExpire");
    localStorage.removeItem("monitor-name");
    localStorage.removeItem("monitor-camera");
    localStorage.removeItem("monitor-step");
    localStorage.removeItem("monitor-steps");
    localStorage.removeItem("monitor-token");
    localStorage.removeItem("monitor-subjectUuId");
    localStorage.removeItem("monitor-tuserid");
    localStorage.removeItem("monitor-is_call");
    localStorage.removeItem("monitor-examUuid");
    localStorage.removeItem("monitor-videoSize");
    localStorage.removeItem("monitor-failCount");
    localStorage.removeItem("monitor-isUpload");
  },
  setSocket(state, arr) {
    if(!arr){
      state.socket = null;
      state.stomp = null;
    }
    if(arr && arr.socket){
      state.socket = arr.socket;
    }
    if(arr && arr.stomp){
      state.stomp = arr.stomp;
    }
  },
};
