export default [
  {
    path: "login",
    name: "login",
    component: () => import("v/index/login/Login"),
    meta: {
      title: "登录",
    },
  },
];
