export default [
    {
        path: "/camera",
        name: "camera",
        component: () => import( "v/index/camera/index"),
        meta: {
            title: "人脸识别",
            authorization:true,
            allowBack:false
        },
    },
    {
        path: "/openBack",
        name: "openBack",
        component: () => import( "v/index/camera/openBack"),
        meta: {
            title: "人脸识别",
            authorization:true,
            allowBack:false
        },
    },
];
