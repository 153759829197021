export default [
    {
        path: "/detail",
        name: "detail",
        component: () => import( "v/index/exam/detail"),
        meta: {
            title: "考场规则",
            authorization:false,
            allowBack:false
        },
    },
    {
        path: "/promise",
        name: "promise",
        component: () => import( "v/index/exam/promise"),
        meta: {
            title: "考试承诺书",
            authorization:false,
            allowBack:false
        },
    },
];
