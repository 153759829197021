import Vue from 'vue'
import Vuex from 'vuex'
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

Vue.use(Vuex)

let state = {
    // 上一页地址
    previousPage: "",
    // 数据
    data: [],
    timerUpload: null,
    socket: null,
    stomp: null,
    timeUpload: 1800,
    isLogin: localStorage.getItem("isLogin") || "0", // 只有1为已登录
};

export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
});
