export default [
    {
        path: "monitor",
        name: "monitor",
        component: () => import( "v/index/monitor/index"),
        meta: {
            title: "视频监控",
            authorization:true
        },
    },
    {
        path: "upload",
        name: "upload",
        component: () => import( "v/index/upload/index"),
        meta: {
            title: "作品及封袋照片上传",
            authorization:true
        },
    },
    {
        path: "/qrcode",
        name: "qrcode",
        component: () => import( "v/index/monitor/qrcode"),
        meta: {
            title: "二维码扫码确认",
            authorization:true
        },
    },
    {
        path: "/photoqr",
        name: "photoqr",
        component: () => import( "v/index/monitor/photoqr"),
        meta: {
            title: "二维码拍照确认",
            authorization:true
        },
    },
];
