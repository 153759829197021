import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/reset.css";
import "./assets/css/override.css";
import "./assets/fonts/iconfont.css";
import { upperFirst, camelCase } from "lodash";
import "./config/element";
import vueEsign from 'vue-esign'
import { Field, Checkbox,Picker,Popup } from 'vant';
Vue.use(Field).use(Checkbox).use(vueEsign).use(Picker).use(Popup);
import VueDragResize from 'vue-drag-resize'
Vue.component('vue-drag-resize', VueDragResize)


import FastClick from "fastclick";
// FastClick.attach(document.body);

Vue.config.productionTip = false;

const requireComponent = require.context(
    // 其组件目录的相对路径
    "./components/basicComponents",
    // 是否查询其子目录
    false,
    // 匹配基础组件文件名的正则表达式
    /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName);

  // 获取组件的 PascalCase 命名
  const componentName = upperFirst(
      camelCase(
          // 剥去文件名开头的 `./` 和结尾的扩展名
          fileName.replace(/^\.\/(.*)\.\w+$/, "$1")
      )
  );

  // 全局注册组件
  Vue.component(
      componentName,
      // 如果这个组件选项是通过 `export default` 导出的，
      // 那么就会优先使用 `.default`，
      // 否则回退到使用模块的根。
      componentConfig.default || componentConfig
  );
});

// // 配置vconsole
// import Vconsole from "vconsole";
// let vConsole = new Vconsole();
// Vue.use(vConsole);


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
