import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import Home from "./index/home";
import Login from "./index/login";
import Monitor from "./index/monitor";
import Position from "./index/position";
import Camera from "./index/camera";
import Exam from "./index/exam";


Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        component: () =>
            import("v/index/Index.vue"),
        children: [
            ...Home,
            ...Login,
            ...Monitor,
            ...Position,
            ...Camera,
            ...Exam
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // 记录上一页
    if (from) {
        store.commit("setPrePage", from.path);
    }
    
    window.document.title = to.meta.title;
    // 1.修改页面标题
    let isLogin = localStorage.getItem("monitor-isLogin");
    if (to.meta.authorization && to.meta.authorization === true) {
        store.commit("setPrePage", to.path);
        // 2.1.获取登录信息
        if (!isLogin) {
            // 2.1.1.未登录，跳转到登录页面
            router.push({
                path: '/login'
            })
        } else {
           
            let isVerify = localStorage.getItem("monitor-isVerify");
            let renzheng = localStorage.getItem("monitor-renzheng");
            if(isVerify && renzheng != 1){
                // router.push({
                //     path: '/position'
                // })
            }
            // router.push({
            //     path: '/position'
            // })
            next();
            // console.log(to.name);
            // if(to.name === 'login'){
            //   router.push({
            //     path: '/'
            //   })
            //   return;
            // }
           
        }
    } else {
        // 2.2.非鉴权路径直接访问
        if (to.name === 'login' && isLogin) {
            let camera = localStorage.getItem("monitor-camera");
            if (!camera) {
                router.push({
                    path: '/position'
                })
            } else {
                let needUpload = localStorage.getItem("monitor-has_step");
                if (needUpload == 0) {
                    router.push({
                        path: '/'
                    })
                } else {
                    router.push({
                        path: '/monitor'
                    })
                }
            }
            return;
        }
        next();
    }

    let allowBack = true
    if (to.meta.allowBack !== undefined) {
        allowBack = to.meta.allowBack
    }
    if (!allowBack) {
        history.pushState(null, null, location.href)
    }    
    // store.dispatch('updateAppSetting', {
    //     allowBack: allowBack
    // })
});

export default router;
