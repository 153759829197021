export default [
    {
        path: "position",
        name: "position",
        component: () => import( "v/index/home/position"),
        meta: {
            title: "选择监控",
            authorization:true
        },
    },
];
