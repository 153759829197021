import Vue from 'vue';
import 'muse-ui/lib/styles/base.less';
import 'vant/lib/icon/local.css'; //引用字体css

import {
    Alert,
    AppBar,
    AutoComplete,
    Avatar,
    Badge,
    BottomNav,
    BottomSheet,
    Breadcrumbs,
    Button,
    Card,
    Checkbox,
    Chip,
    DateInput,
    DataTable,
    Dialog,
    Divider,
    Drawer,
    ExpansionPanel,
    Form,
    Grid,
    GridList,
    Helpers,
    Icon,
    List,
    LoadMore,
    Menu,
    Pagination,
    Paper,
    Picker,
    Popover,
    Progress,
    Radio,
    Select,
    SlidePicker,
    Slider,
    Snackbar,
    Stepper,
    SubHeader,
    Switch,
    Tabs,
    TextField,
    Tooltip,
} from 'muse-ui';
import theme from 'muse-ui/lib/theme';
import * as colors from 'config/colors';

theme.add('custom-theme', {
    primary: colors.indigo,
    secondary: colors.pinkA200
});
//import 'muse-ui/lib/styles/theme.less';



Vue.use(Alert);
Vue.use(AppBar);
Vue.use(AutoComplete);
Vue.use(Avatar);
Vue.use(Badge);
Vue.use(BottomNav);
Vue.use(BottomSheet);
Vue.use(Breadcrumbs);
Vue.use(Button);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(Chip);
Vue.use(DateInput);
Vue.use(DataTable);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(Drawer);
Vue.use(ExpansionPanel);
Vue.use(Form);
Vue.use(Grid);
Vue.use(GridList);
Vue.use(Helpers);
Vue.use(Icon);
Vue.use(List);
Vue.use(LoadMore);
Vue.use(Menu);
Vue.use(Pagination);
Vue.use(Paper);
Vue.use(Picker);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(Radio);
Vue.use(Select);
Vue.use(SlidePicker);
Vue.use(Slider);
Vue.use(Snackbar);
Vue.use(Stepper);
Vue.use(SubHeader);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(TextField);
Vue.use(Tooltip);

import {
    Button as vButton,
    NavBar as vNavBar,
    Image as VanImage,
    Dialog as vDialog,
    Circle as vanCircle,
    Icon as vanIcon,
    Col, Row,Uploader,
    Step, Steps,Overlay,CellGroup,Field,Loading
} from 'vant';

Vue.use(vButton);
Vue.use(vNavBar);
Vue.use(VanImage);
Vue.use(vDialog);
Vue.use(vanCircle);
Vue.use(vanIcon);
Vue.use(Col);
Vue.use(Row);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Uploader);
Vue.use(Overlay);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Loading);
